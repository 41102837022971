<template>
  <div class="SetCommissionViewContent">
    <div>
      <InputPrefix
        dinput-in
        :value="user.commissionPercent"
        name="commissionPercent"
        prefix="%"
        dir="rtl"
        :limit="100"
        @input="(value) => (percent = value)"
      />
    </div>
    <Spacer num="1" />
    <Confirm :confirm="confirm" />
  </div>
</template>

<script>
export default {
  props: ["user", "modal", "statistics"],
  data: function() {
    return {
      percent: this.user.commissionPercent,
    };
  },
  computed: {
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
          action: () => {
            this.modal.close();
          },
        },
        accept: {
          label: this.$locale["accept"],
          action: async () => {
            try {
              this.isLoading(true);
              this.payload = {
                userId: this.user._id,
                percent: Number(this.percent),
              };
              this.req = await this.api.post(`/admin/setUserCommissionPercent`, this.payload);

              if (!this.req.data.success) {
                this.isLoading(false);
                return this.alert(this.req.data);
              }

              this.isLoading(false);

              this.modal.close(() => {
                this.$store.state.appKey++;
                this.alert(this.req.data);
                this.$store.dispatch("closeAllModals");
              });
            } catch (error) {
              this.isLoading(false);
              this.alert(Go.getErrorMessage(error));
            }
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.SetCommissionViewContent {
  .InputPrefix input {
    text-align: center;
    font-size: 120%;
    font-family: $third_font_bold;
  }
}
</style>
